<template>
    <div class="aircraft-text-container">
        <span class="aircraft-description">
            Летательный аппарат собран пользователем музея “Полет”
        </span>
        <template>
            <AircraftTextSkeleton v-if="date === undefined"/>
            <span class="aircraft-date" v-else>
                {{ new Date(date).toLocaleString() }}
            </span>
        </template>
    </div>
</template>

<script>
    import AircraftTextSkeleton from "@/components/AircraftTextSkeleton";

    export default {
        name: "AircraftText",
        components: {AircraftTextSkeleton},
        props: ['date'],
    }
</script>

<style lang="scss">
    .aircraft-text-container {
        width: 189px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
    }

    .aircraft-description,
    .aircraft-date {
        font-family: $jetBrainsMono;
        font-size: $aircraftTextSize;
        font-weight: $lighterWeight;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.215em;
    }

    .aircraft-description {
        margin: 0 0 10px 0;
        padding: 0 0 10px 0;
        border-bottom: 1px solid $colorBlack;
    }

    .aircraft-date {
        padding: 10px 0 0 0;
        margin: 0 0 26px 0;
    }
</style>