<template>
    <div class="aircraft-container">
        <ReturnButton/>
        <AircraftBanner :image="aircraft.file"/>
        <AircraftText :date="aircraft.created_at"/>
    </div>
</template>

<script>
    import AircraftBanner from "@/components/AircraftBanner";
    import AircraftText from "@/components/AircraftText";
    import ReturnButton from "@/components/ReturnButton";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "AircraftPage",
        components: {ReturnButton, AircraftText, AircraftBanner},
        methods: {
          ...mapActions(['getAircraftPage']) ,
        },
        computed: {
            ...mapGetters({aircraft: 'getPageItems'}),
            getAircraftId() {
                return this.$route.params.id
            },
        },
        created() {
            this.getAircraftPage(this.getAircraftId);
        }
    }
</script>

<style lang="scss">
    .aircraft-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $colorBlack;
        padding: 10px;
    }

    .skeleton {
        animation: skeleton-loading 1s infinite alternate;
    }

    @keyframes skeleton-loading {
        0% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
</style>