<template>
    <router-link to="/" class="return-button">
        <span class="return-button-text">В галерею</span>
    </router-link>
</template>

<script>
    export default {
        name: "ReturnButton"
    }
</script>

<style lang="scss">
.return-button {
    position: absolute;
    top: 10px;
    left: 10px;
    text-decoration: none;
    padding: 10px;
    border: 1px solid $colorBlack;
    color: $colorBlack;
}
.return-button-text {
    font-family: $jetBrainsMono;
    font-size: $headText;
    font-weight: $boldWeight;
    text-transform: uppercase;
}
.return-button-text:before {
    content: '';
    padding: 10px;
    margin: 0 5px 0 0;
    background-image: url("../img/arr.png");
    background-repeat: no-repeat;
    background-position: center;
}
</style>