<template>
    <span class="aircraft-date-skeleton skeleton"></span>
</template>

<script>
    export default {
        name: "AircraftTextSkeleton"
    }
</script>

<style lang="scss">
    /*.skeleton-wrapper {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    align-items: center;*/
    /*}*/

    .aircraft-date-skeleton {
        display: block;
        height: 10px;
        width: 100%;
        margin: 0 0 26px 0;
        background-color: $skeletonColor;
    }
</style>