<template>
    <div class="grid-skeleton-item">
        <span class="grid-image-skeleton skeleton"></span>
        <div class="grid-skeleton-content">
            <span class="grid-skeleton-description skeleton"></span>
            <span class="grid-skeleton-arrow skeleton"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainGridItemSkeleton"
    }
</script>

<style lang="scss">
    .grid-skeleton-item {
        margin: 40px 0;
    }

    .grid-image-skeleton {
        display: block;
        height: 228px;
        background-color: $skeletonColor;
        margin: 0 0 10px 0;
    }

    .grid-skeleton-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .grid-skeleton-text {
        display: flex;
        flex-direction: column;
    }

    .grid-skeleton-description {
        width: 80%;
        height: 15px;
        background-color: $skeletonColor;
        margin: 0 20px 0 0;
    }

    .grid-skeleton-arrow {
        display: block;
        width: 30px;
        height: 15px;
        background-color: $skeletonColor;
        /*margin: 55px 0 0 0;*/
    }
</style>