<template>
    <h1 class="head-title">Галерея<br/>летательных<br/>аппаратов</h1>
</template>

<script>
    export default {
        name: "MainHeadTitle"
    }
</script>

<style lang="scss">
    .head-title {
        font-size: $headTitle;
        font-weight: $boldWeight;
        line-height: 52.8px;
        text-align: end;
        margin: 0 0 20px 0;
    }
</style>