<template>
    <div class="main-section-container">
        <MainGrid>
            <MainGridItem v-for="({id, file, created_at}) in items" :key="id" :image="file" :text="created_at" :id="id"/>
        </MainGrid>
    </div>
</template>

<script>
    import MainGrid from "@/components/MainGrid";
    import MainGridItem from "@/components/MainGridItem";

    export default {
        name: "MainSection",
        props: ['items'],
        components: {MainGridItem, MainGrid},
    }
</script>

<style lang="scss">
    .main-section-container {
        display: flex;
        flex-direction: column;
    }
</style>