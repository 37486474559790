<template>
    <span class="head-text">Посмотрите на летательные аппараты будущего, которые были собраны другими посетителями музея.</span>
</template>

<script>
    export default {
        name: "MainHeadText"
    }
</script>

<style lang="scss">
    .head-text {
        font-size: $headText;
        font-weight: $regularWeight;
        line-height: 19.6px;
        text-align: right;
    }
</style>