import Vue from "vue";
import Vuex from "vuex";
import {API_URL} from "@/constants/api";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        aircraftItems: {
            items: [],
            code: null,
            status: null,
            isLoading: null,
        },
        aircraftPageItems: {
            items: [],
            code: null,
            status: null,
            isLoading: null,
        },
    },
    mutations: {
        setAircraftItems(state, {data}) {
            state.aircraftItems.items = data;
        },
        setAircraftPage(state, {data}) {
            state.aircraftPageItems.items = data;
        },
    },
    getters: {
        getItems(state) {
            return state.aircraftItems.items;
        },
        getPageItems(state) {
          return state.aircraftPageItems.items;
        },
    },
    actions: {
        getAircraftItems(ctx) {
            fetch(`${API_URL.aircraft}list`)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    ctx.commit("setAircraftItems", response);
                    console.log(response)
                });
        },
        getAircraftPage(ctx, payload) {
            fetch(`${API_URL.aircraft}${payload}`)
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    ctx.commit("setAircraftPage", response)
                });
        },
    },
});
