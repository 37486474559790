<template>
    <div class="container">
        <MainHead/>
        <template>
            <MainSkeleton v-if="getItems.length === 0"/>
            <MainSection :items="getItems" v-else/>
        </template>
    </div>
</template>

<script>
    import MainHead from "@/components/MainHead";
    import MainSection from "@/components/MainSection";
    import {mapActions, mapGetters} from "vuex";
    import MainSkeleton from "../components/MainSkeleton";

    export default {
        name: "MainPage",
        components: {MainSkeleton, MainSection, MainHead},
        computed: {
          ...mapGetters(['getItems']),
        },
        methods: {
            ...mapActions(['getAircraftItems']),
        },
        created() {
            this.getAircraftItems();
        }
    }
</script>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid $colorBlack;
    }
</style>