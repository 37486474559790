<template>
    <div class="banner-wrapper">
        <AircraftBannerSkeleton v-if="image === undefined"/>
        <img :src="`${ image }`" alt="" class="banner" v-else>
    </div>
</template>

<script>
    import AircraftBannerSkeleton from "@/components/AircraftBannerSkeleton";

    export default {
        name: "AircraftBanner",
        components: {AircraftBannerSkeleton},
        props: ['image'],
    }
</script>

<style lang="scss">
    .banner-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 100%;
    }

    .banner {
        width: 100%;
        max-height: 231px;
    }
</style>