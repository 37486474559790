<template>
    <span class="aircraft-banner-skeleton skeleton"></span>
</template>

<script>
    export default {
        name: "AircraftBannerSkeleton"
    }
</script>

<style lang="scss">
    .aircraft-banner-skeleton {
        display: block;
        width: 333px;
        min-height: 213px;
        background-color: $skeletonColor;
    }
</style>