<template>
    <router-link :to="{ path: `/aircraft/${id}` }" class="link-to-aircraft">
        <div class="grid-item">
            <div class="image-wrapper" :style="{backgroundImage: `url(${image})`}"></div>
            <span class="grid-item-text">{{new Date(text).toLocaleString()}}</span>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: "MainGridItem",
        props: ['image', 'text', 'id'],
    }
</script>

<style lang="scss">
    .grid-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0 0 0;
        margin: 0 0 20px 0;
        border-top: 1px dashed $colorBlack;
    }

    .link-to-aircraft {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: $colorBlack;
    }

    .image-wrapper {
        width: 100%;
        min-height: 218px;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 0 35px 0;
    }

    .grid-item-text {
        font-family: $jetBrainsMono;
        font-size: $headText;
        font-weight: $lighterWeight;
        line-height: 14px;
        letter-spacing: 0.215em;
        text-transform: uppercase;
        opacity: 0.6;
    }

    .grid-item-text:after {
        content: '';
        padding: 10px;
        margin: 0 0 0 45px;
        background-image: url("../img/arrow.png");
        background-repeat: no-repeat;
        background-position: center;
    }
</style>